(function($) {
	const initMaps = function() {
		$('.gmap.gmap--location').each(function() {
			const args = {
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				styles: [
					{
						featureType: 'administrative',
						elementType: 'labels.text.fill',
						stylers: [
							{
								color: '#444444',
							},
						],
					},
					{
						featureType: 'landscape',
						elementType: 'all',
						stylers: [
							{
								color: '#f2f2f2',
							},
						],
					},
					{
						featureType: 'poi',
						elementType: 'all',
						stylers: [
							{
								visibility: 'off',
							},
						],
					},
					{
						featureType: 'road',
						elementType: 'all',
						stylers: [
							{
								saturation: -100,
							},
							{
								lightness: 45,
							},
							{
								visibility: 'off',
							},
						],
					},
					{
						featureType: 'road.highway',
						elementType: 'all',
						stylers: [
							{
								visibility: 'simplified',
							},
						],
					},
					{
						featureType: 'transit',
						elementType: 'all',
						stylers: [
							{
								visibility: 'off',
							},
						],
					},
					{
						featureType: 'water',
						elementType: 'all',
						stylers: [
							{
								color: '#E7E5E2',
							},
						],
					},
					{
						featureType: 'administrative',
						elementType: 'labels',
						stylers: [
							{
								visibility: 'on',
							},
						],
					},
					{
						featureType: 'road',
						elementType: 'geometry',
						stylers: [
							{
								color: '#ffffff',
							},
							{
								visibility: 'simplified',
							},
						],
					},

				],
			};

			const lat = parseFloat($(this).attr('data-lat'));
			const lng = parseFloat($(this).attr('data-lng'));
			args.center = new google.maps.LatLng(lat, lng);

			const map = new google.maps.Map(this, args);

			map.setZoom(14);

			const marker = new google.maps.Marker({
				position: new google.maps.LatLng(lat, lng),
				map,
				icon: {
					url: `${ wkScriptVars.themeUrl }/assets/dist/images/pin.svg`,
					scaledSize: new google.maps.Size(48, 48),
					anchor: new google.maps.Point(24, 0),
				},
			});

			marker.setMap(map);
		});
	};

	if (typeof google !== 'undefined') {
		google.maps.event.addDomListener(window, 'load', initMaps);
	}
}(window.jQuery));
