import {MarkerClusterer} from '@googlemaps/markerclusterer';

(function($) {
	const initMaps = function() {
		$('.gmap.gmap--map').each(function() {
			const args = {
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				styles: [
					{
						featureType: 'administrative',
						elementType: 'labels.text.fill',
						stylers: [
							{
								color: '#444444',
							},
						],
					},
					{
						featureType: 'landscape',
						elementType: 'all',
						stylers: [
							{
								color: '#f2f2f2',
							},
						],
					},
					{
						featureType: 'poi',
						elementType: 'all',
						stylers: [
							{
								visibility: 'off',
							},
						],
					},
					{
						featureType: 'road',
						elementType: 'all',
						stylers: [
							{
								saturation: -100,
							},
							{
								lightness: 45,
							},
							{
								visibility: 'off',
							},
						],
					},
					{
						featureType: 'road.highway',
						elementType: 'all',
						stylers: [
							{
								visibility: 'simplified',
							},
						],
					},
					{
						featureType: 'road.arterial',
						elementType: 'labels.icon',
						stylers: [
							{
								visibility: 'off',
							},
						],
					},
					{
						featureType: 'transit',
						elementType: 'all',
						stylers: [
							{
								visibility: 'off',
							},
						],
					},
					{
						featureType: 'water',
						elementType: 'all',
						stylers: [
							{
								color: '#E7E5E2',
							},
						],
					},
					{
						featureType: 'administrative',
						elementType: 'labels',
						stylers: [
							{
								visibility: 'on',
							},
						],
					},
					{
						featureType: 'road',
						elementType: 'geometry',
						stylers: [
							{
								color: '#ffffff',
							},
							{
								visibility: 'simplified',
							},
						],
					},
					{
						featureType: 'road',
						elementType: 'labels',
						stylers: [{visibility: 'off'}],
					},
				],
			};

			if ($(this).data('zoom')) {
				args.zoom = parseInt($(this).data('zoom-mobile') && $(window).width() < 768 ? $(this).data('zoom-mobile') : $(this).data('zoom'));
			}

			if ($(this).data('center-lat') !== undefined && $(this).data('center-lng') !== undefined) {
				args.center = {lat: parseFloat($(this).data('center-lat')), lng: parseFloat($(this).data('center-lng'))};

				if ($(window).width() < 1024) {
					args.center = {lat: 46.8678368, lng: 8.1718868};
				}
			}

			const map = new google.maps.Map(this, args);
			const markers = [];

			if ($(this).data('type') === 'locations') {
				(async () => {
					let totalPages = 1;
					let page = 1;
					while (page <= totalPages) {
						const res = await fetch(`${ wkScriptVars.restUrlMain }wp/v2/locations?_fields=id,title,acf.google_maps,acf.pop_up_text,acf.pop_up_link,acf.map_region,acf.map_municipality,acf.zip,acf.city,acf.address&page=${ page }`);
						const locations = await res.json();

						locations.forEach(location => {
							const marker = new google.maps.Marker({
								position: {
									lat: parseFloat(location.acf.google_maps.lat),
									lng: parseFloat(location.acf.google_maps.lng),
								},
								icon: {
									url: `${ wkScriptVars.themeUrl }/assets/dist/images/pin.svg`,
									scaledSize: new google.maps.Size(44, 44),
									anchor: new google.maps.Point(22, 44),
									labelOrigin: new google.maps.Point(22, 44),
								},
								fontFamily: 'Neue Haas Grotesk Text Pro',
								fontWeight: '400',
								fontSize: '16px',
								map,
								title: location.title.rendered,
								metadata: {municipality_id: location.acf.map_municipality, region_id: location.acf.map_region},
								state: {active: false},
							});

							if (location.acf.pop_up_text) {
								location.acf.pop_up_text = location.acf.pop_up_text.replace(/(?:\r\n|\r|\n)/g, '<br>');
							}

							const contentString = `
							${
					location.acf.pop_up_link
						? `<a href="${ location.acf.pop_up_link.url }" class="maps__pop-up-link" target="${ location.acf.pop_up_link.target }">
								  ${ location.acf.pop_up_link.title ? location.acf.pop_up_link.title : location.title.rendered }
								</a>`
						: `<p class="maps__pop-up-text mb-0">${ location.title.rendered }</p>`
					}

							${ location.acf.pop_up_text ? `<p class="maps__pop-up-text mb-0">${ location.acf.pop_up_text }</p>` : '' }
							${ location.acf.address ? `<p class="maps__pop-up-text mb-0">${ location.acf.address }</p>` : '' }
							${ location.acf.city ? `<p class="maps__pop-up-text mb-0"> ${ location.acf.zip } ${ location.acf.city }</p>` : '' }
						  `;

							marker.infowindow = new google.maps.InfoWindow({
								position: {
									lat: parseFloat(location.acf.google_maps.lat),
									lng: parseFloat(location.acf.google_maps.lng),
								},
								pixelOffset: new google.maps.Size(0, -44),
								content: contentString,
							});

							marker.addListener('click', () => {
								closeAllInfoWindows(markers);
								map.setZoom(14);
								map.panTo(marker.position);
								marker.infowindow.open(map);
								setMarkerActive(marker);
							});

							google.maps.event.addListener(marker.infowindow, 'closeclick', function() {
								setMarkerInactive(marker);
							});

							markers.push(marker);

							const navItem = $('.maps__location[data-id="' + location.acf.map_municipality + '"]');

							navItem.on('click', function() {
								closeAllInfoWindows(markers);
								marker.infowindow.open(map);
								setMarkerActive(marker);
								map.setZoom(14);
								map.panTo(marker.position);
							});
						});
						totalPages = res.headers.get('X-WP-TotalPages');
						page++;
					}

					const markerClusterer = new MarkerClusterer({
						map,
						markers,
						renderer: {
							render({count, position}) {
								return new google.maps.Marker({
									label: {
										text: String(count),
										color: 'black',
										fontFamily: 'Neue Haas Grotesk Text Pro',
										fontWeight: '400',
										fontSize: '16px',
									},
									position,
									icon: {
										url: `${ wkScriptVars.themeUrl }/assets/dist/images/pin.svg`,
										scaledSize: new google.maps.Size(44, 44),
										anchor: new google.maps.Point(22, 44),
										labelOrigin: new google.maps.Point(22, 16),
									},
									zIndex: google.maps.Marker.MAX_ZINDEX + count,
								});
							},
						},
					});

					markerClusterer.addListener('clusteringend', clusterer => {
						clusterer.clusters.forEach(cluster => {
							if (cluster.markers.length > 1) {
								cluster.markers.forEach(marker => {
									marker.infowindow.close();
								});
							}
						});
					});

					const location = $('.maps__location[data-id="' + wkScriptVars.municipalityId + '"]').first();

					if (location.length > 0) {
						map.setZoom(14);
						map.panTo({lat: parseFloat(location.data('lat')), lng: parseFloat(location.data('lng'))});
						const marker = markers.find(marker => marker.metadata.municipality_id === location.data('id'));
						if (marker) {
							marker.infowindow.open(map);
							setMarkerActive(marker);
						}
					}
				})();
			}
		});
	};

	if (typeof google !== 'undefined') {
		google.maps.event.addDomListener(window, 'load', initMaps);
	}
}(window.jQuery));

function setMarkerActive(marker) {
	marker.state.active = true;
	marker.setIcon({
		url: `${ wkScriptVars.themeUrl }/assets/dist/images/pin-active.svg`,
		scaledSize: new google.maps.Size(44, 44),
		anchor: new google.maps.Point(22, 44),
		labelOrigin: new google.maps.Point(22, 44),
	});

	const navItem = document.querySelector(`.maps__location[data-id="${ marker.metadata.municipality_id }"]`);
	navItem.classList.toggle('active');
}

function setMarkerInactive(marker) {
	marker.state.active = false;
	marker.setIcon({
		url: `${ wkScriptVars.themeUrl }/assets/dist/images/pin.svg`,
		scaledSize: new google.maps.Size(44, 44),
		anchor: new google.maps.Point(22, 44),
		labelOrigin: new google.maps.Point(22, 44),
	});
	const navItem = document.querySelector(`.maps__location[data-id="${ marker.metadata.municipality_id }"]`);
	navItem.classList.toggle('active');
}

function closeAllInfoWindows(markers) {
	markers.forEach(marker => {
		if (marker.state.active) {
			marker.infowindow.close();
			setMarkerInactive(marker);
			const navItem = document.querySelector('.maps__location[data-id="' + marker.metadata.municipality_id + '"]');
			navItem.classList.remove('active');
		}
	});
}
