import Masonry from 'masonry-layout';

const masonryElement = document.querySelector('.blog-teaser__grid');

if (masonryElement) {
	const masonry = new Masonry('.blog-teaser__grid',
		{
			gutter: 32,
			itemSelector: '.blog-teaser__item',
			percentPosition: true,
			columnWidth: '.blog-teaser__item',
		},
	);

	document.addEventListener('facetwp-loaded', () => {
		masonry.reloadItems();
		masonry.layout();
	});
}
