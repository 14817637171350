($ => {
	const header = $('.header');
	$(window).scroll(function() {
		const scroll = $(window).scrollTop();
		if (scroll > header.height()) {
			header.addClass('scrolling');
		}
		if (header.hasClass('scrolling') && scroll < header.height()) {
			header.removeClass('scrolling');
		}
	});
})(window.jQuery);

