const eventDownloadButtons = document.querySelectorAll('.event__download, .single-event__download');

eventDownloadButtons.forEach((button) => {
	const eventFile = button.getAttribute('data-file'); // The raw ICS content
	const eventFileName = button.getAttribute('data-file-name'); // The desired filename for the download

	button.addEventListener('click', (e) => {
		e.preventDefault();

		// Ensure that the content is passed directly to the Blob
		const blob = new Blob([eventFile], { type: 'text/calendar;charset=utf-8' });
		const url = URL.createObjectURL(blob);

		// Create a temporary <a> element to trigger the download
		const tempLink = document.createElement('a');
		tempLink.href = url;
		tempLink.download = eventFileName;

		// Append the link to the body
		document.body.appendChild(tempLink);

		// Programmatically click the link to trigger the download
		tempLink.click();

		// Remove the link from the document and revoke the URL
		document.body.removeChild(tempLink);
		URL.revokeObjectURL(url);
	});
});
