// manifest
import 'pwacompat/src/pwacompat';

// add better focus and input type detection on body element (for development)
// import 'what-input';

// components
import './components/aucorNavigation';
import './components/bootstrap';
import './components/headerAnimation';
import './components/map';
import './components/cookieBanner';
import './components/postArchiveGrid';
import './components/location';
import './components/newsletterForm';
import './components/eventDownload';
// import './components/facetFilters';

// infinte scroll for facetwp
// import './components/lazyLoading';
