(($) => {
	const newsletterForm = $('.footer-newsletter form');

	if (newsletterForm.length >= 1) {
		// get the value of action attribute
		const action = newsletterForm.attr('action');

		// remove anything that comes befor the last slash
		const actionUrl = action.substring(action.lastIndexOf('/') + 1);

		// add the action url to the form
		newsletterForm.attr('action', '/' + actionUrl);
	}
})(window.jQuery);
