const {Cookies} = window;
const cookieName = 'wk-consent';
const cookieBanner = document.querySelector('.cookie-message');

($ => {
	if (Cookies) {
		if (Cookies.get(cookieName) === undefined) {
			if (cookieBanner) {
				const declineValue = cookieBanner.querySelector('.cookie-message__submit--decline').value;
				const closeButtonMarkup = `<button class="cookie-message__close js-cookie-submit" type="button" value="${ declineValue }"><span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="currentColor"><circle r="15" fill="black" cy="50%" cx="50%"/><path d="M25 0C11.225 0 0 11.225 0 25s11.225 25 25 25 25-11.225 25-25S38.775 0 25 0zm8.4 30.75a1.886 1.886 0 0 1 0 2.65c-.375.375-.85.55-1.325.55s-.95-.175-1.325-.55L25 27.65l-5.75 5.75c-.375.375-.85.55-1.325.55s-.95-.175-1.325-.55a1.886 1.886 0 0 1 0-2.65L22.35 25l-5.75-5.75a1.886 1.886 0 0 1 0-2.65 1.886 1.886 0 0 1 2.65 0L25 22.35l5.75-5.75a1.886 1.886 0 0 1 2.65 0 1.886 1.886 0 0 1 0 2.65L27.65 25l5.75 5.75z"/></svg></span><span class="visually-hidden">${ window.wkScriptVars.cookieClose }</span></button>`;
				cookieBanner.insertAdjacentHTML('beforeend', closeButtonMarkup);

				$('.js-cookie-submit').click(function() {
					const consentEvent = $(this).val();
					Cookies.set(cookieName, consentEvent, {expires: 30});
					$('.cookie-message').fadeOut();
					$('.cookie-consent__overlay').fadeOut();
					$('.cookie-consent__overlay').removeClass('active');
					if (consentEvent !== '') {
						if (window.wkConsentVars.use_matomo === 'off') {
							window.dataLayer = window.dataLayer || [];
							window.dataLayer.push({
								event: consentEvent,
							});
						} else if (window.wkConsentVars.use_matomo === 'on') {
							window._mtm = window._mtm || [];
							window._mtm.push({
								event: consentEvent,
							});
						}
					}
				});
			}
		}
	}
})(window.jQuery);
